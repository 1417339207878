import Flickity from "flickity";
require("flickity-imagesloaded");

const flickityElementsArray = [...document.querySelectorAll(".rs-Carousel")];

flickityElementsArray.forEach((flickityElement) => {
  flickityElement.classList.add("rs-Carousel--loaded");

  const flickity = new Flickity(
    flickityElement,
    JSON.parse(flickityElement.dataset.flickityConfig),
  );
  flickity.reposition();
});
